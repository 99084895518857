import { ThemeService } from 'libs/infrastructure/theme/theme.service';
import {
  AfterViewInit,
  Component,
  ViewContainerRef,
  inject
} from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { I18nService, PageTitleService } from 'libs/infrastructure';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { fadeOnEnterLeaveAnimation } from 'libs/utils';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ToastService } from 'libs/components/legacy/toast';
import * as fromPropertyListState from 'homepage/+state/property-list/property-list.reducers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from 'homepage-env';
import { LoadingSpinnerComponent } from 'libs/components/legacy';
import { AsyncPipe } from '@angular/common';
import { ThemeComponent } from 'libs/infrastructure/theme/components/theme/theme.component';
import { ThemeDirective } from 'libs/infrastructure/theme/theme.directive';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeOnEnterLeaveAnimation],
  imports: [
    LoadingSpinnerComponent,
    AsyncPipe,
    RouterOutlet,
    ThemeComponent,
    ThemeDirective
  ],
  standalone: true
})
export class AppComponent implements AfterViewInit {
  toastService = inject(ToastService);
  viewContainerRef = inject(ViewContainerRef);
  private pageTitleService = inject(PageTitleService);
  private store = inject<Store<fromBaseState.AppState>>(Store);
  private activatedRoute = inject(ActivatedRoute);
  private i18nService = inject(I18nService);
  private themeService = inject(ThemeService);
  private translate = inject(TranslateService);

  public finishedLoaded$: Observable<boolean>;

  public constructor() {
    const viewContainerRef = this.viewContainerRef;

    this.pageTitleService.run(this.activatedRoute);
    this.i18nService.loadDefaultTranslations();
    this.toastService.setRootViewContainerRef(viewContainerRef);
    this.finishedLoaded$ = this.store.select(
      fromBaseState.getTranslationLoaded
    );
    this.themeService.init(environment);
  }

  ngAfterViewInit(): void {
    this.store
      .select(fromPropertyListState.getError)
      .pipe(untilDestroyed(this))
      .subscribe(error => {
        if (error) {
          this.toastService.error(
            this.translate.instant('general.unexpected_error_occurred_m')
          );
        }
      });
    this.store
      .select(fromPropertyListState.getOptions)
      .pipe(untilDestroyed(this))
      .subscribe(options => {
        if (options) {
          const theme = options.branding.theme;
          const activeTheme = {
            logo: options.branding.logo,
            favicon: options.branding.favicon,
            theme: {
              ...theme
            }
          };
          this.themeService.createTheme(activeTheme);
          this.themeService.setTheme(activeTheme.theme.name);
        }
      });
  }
}

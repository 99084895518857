<div class="properties">
  <div class="properties__top">
    <app-properties-filter
      (form)="sortChanges($event)"
      [pagination]="pagination$ | async"
      [isMapView]="isMapView"
      (isMap)="viewChanges($event)"
      [options]="options"
    ></app-properties-filter>
    @if (isCardView && (properties$ | async) && (properties$ | async)?.length) {
      <app-properties-card-list
        [properties]="properties$ | async"
        [pagination]="pagination$ | async"
        [isLoading]="isLoading$ | async"
        (intersect)="getProperties($event)"
      >
      </app-properties-card-list>
    }
    @if (isListView && !isMapView) {
      <app-properties-table
        [properties]="properties$ | async"
        [options]="options"
        [customerId]="customerId"
      >
      </app-properties-table>
    }
  </div>
  @if (isMapView && (properties$ | async)) {
    <app-properties-map [properties]="properties$ | async"></app-properties-map>
  }
  @if ((isLoading$ | async) && !(properties$ | async)?.length) {
    <div class="loading-area">
      <app-loading-spinner></app-loading-spinner>
    </div>
  }
  @if (
    isCardView &&
    !(properties$ | async)?.length &&
    (isLoading$ | async) === false
  ) {
    <div class="py-5">
      <app-no-data-disclaimer
        [title]="'general.no_properties_headline'"
        [description]="'general.no_properties'"
      ></app-no-data-disclaimer>
    </div>
  }
</div>

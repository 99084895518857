import { defaultEnvironment } from './environment.defaults';

export const environment = {
  ...defaultEnvironment,

  production: false,
  development: false,
  graphql_server_uri: 'https://gql-hp.itg.immomio.com/homepage/graphql',
  graphql_host: 'https://gql-hp.itg.immomio.com',
  backend_url: 'https://api-ps-ext.itg.immomio.com',
  property_searcher_base_url: 'https://tenant.itg.immomio.com',
  homepage_app_url: 'https://homepage.itg.immomio.com',
  tileServerUrl:
    'https://tiles-dev.geo.immomio.com/styles/basic-preview/{z}/{x}/{y}.png'
};

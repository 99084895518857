/*! Ionic Portals: https://ionic.io/portals - Commercial License */
import { registerPlugin, WebPlugin, Capacitor } from '@capacitor/core';
class PortalsWeb extends WebPlugin {
  async publishNative(_message) {
    return Promise.resolve();
  }
  addListener(_eventName, _listenerFunc) {
    return Promise.reject('Method not implemented on web.');
  }
}
const Portals = registerPlugin('Portals', {
  web: () => new PortalsWeb()
});
/**
 * Provides access to any initial state provided by the native application.
 * If the web application is running in a Portal, this will always be defined
 * with the name property.
 * */
function getInitialContext() {
  if (Capacitor.getPlatform() === 'android') {
    // eslint-disable-next-line
    //@ts-ignore
    return JSON.parse(AndroidInitialContext.initialContext());
  } else {
    return window.portalInitialContext;
  }
}
function subscribe(topic, callback) {
  return Portals.addListener(topic, callback);
}
function publish(message) {
  return Portals.publishNative(message);
}
export { getInitialContext, publish, subscribe };

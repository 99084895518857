<div class="properties-table row">
  <section class="col-12 col-lg-6" [class.d-hide]="!svgDataList.length">
    <div class="d-flex flex-column position-relative">
      <div class="properties-table__svg-container-list" #svgContainerList>
        @for (svgData of svgDataList; track svgData; let i = $index) {
          <div
            class="properties-table__svg-container"
            [@openCloseSvgContainer]="
              i === selectedSvgContainerIndex ? 'open' : 'closed'
            "
            [ngClass]="{
              'properties-table__svg-container--active':
                i === selectedSvgContainerIndex
            }"
            [innerHTML]="svgData"
          ></div>
        }
      </div>
      @if (selectedSvgContainerIndex !== 0) {
        <app-button
          class="properties-table__svg-back-button align-self-start"
          [size]="'small'"
          [ghost]="true"
          [iconLeft]="'arrow-left'"
          (clickEvent)="onClickSvgBackButton()"
        >
          {{ 'general.to_the_overview_a' | translate }}
        </app-button>
      }
    </div>
  </section>
  <section
    class="col-12 d-flex flex-column justify-content-center position-relative"
    [class.col-lg-6]="show3dVisualisation"
    #table
  >
    <app-data-table
      [data]="properties"
      [columns]="columns"
      [isLoading]="isLoading"
      [class.scrollable-data-table-wrapper]="show3dVisualisation"
    >
      <ng-template let-item appTableCell="objectId">
        <app-data-table-cell
          [attr.data-row-id]="getEncodedId(item.externalId)"
          (mouseenter)="onMouseenterPropertyTableCell(item.externalId)"
          (click)="onClickPropertyTableCell(item)"
        >
          <ng-template #cellData>
            {{ item.externalId ? item.externalId : '-' }}
          </ng-template>
        </app-data-table-cell>
      </ng-template>

      <ng-template let-item appTableCell="etage">
        <app-data-table-cell
          [icon]="item.floor !== null ? 'floor' : ''"
          (mouseenter)="onMouseenterPropertyTableCell(item.externalId)"
          (click)="onClickPropertyTableCell(item)"
        >
          <ng-template #cellData>
            {{ item.floor !== null ? item.floor : '-' }}
          </ng-template>
        </app-data-table-cell>
      </ng-template>

      <ng-template let-item appTableCell="totalRooms">
        <app-data-table-cell
          [icon]="item.totalRooms ? 'rooms' : ''"
          (mouseenter)="onMouseenterPropertyTableCell(item.externalId)"
          (click)="onClickPropertyTableCell(item)"
        >
          <ng-template #cellData>
            {{ item.totalRooms ? (item.totalRooms | number: '1.0') : '-' }}
          </ng-template>
        </app-data-table-cell>
      </ng-template>

      <ng-template let-item appTableCell="size">
        <app-data-table-cell
          [icon]="item.size !== null ? 'area-size' : ''"
          (mouseenter)="onMouseenterPropertyTableCell(item.externalId)"
          (click)="onClickPropertyTableCell(item)"
        >
          <ng-template #cellData>
            {{ item.size ? (item.size | number) + ' &#13217;' : '-' }}
          </ng-template>
        </app-data-table-cell>
      </ng-template>

      <ng-template let-item appTableCell="totalRentGross">
        <app-data-table-cell
          [icon]="item.totalRentGross !== null ? 'coin' : ''"
          (mouseenter)="onMouseenterPropertyTableCell(item.externalId)"
          (click)="onClickPropertyTableCell(item)"
        >
          <ng-template #cellData>
            {{
              item.totalRentGross
                ? (item.totalRentGross | currency: 'EUR') + ' /mtl.'
                : '-'
            }}
          </ng-template>
        </app-data-table-cell>
      </ng-template>

      <ng-template let-item appTableCell="status">
        <app-data-table-cell
          (mouseenter)="onMouseenterPropertyTableCell(item.externalId)"
          (click)="onClickPropertyTableCell(item)"
        >
          <ng-template #cellData>
            <app-badge [color]="getStatusBadgeColor(item.status)">
              {{ 'PROPERTY_STATUS_' + item.status | translate }}
            </app-badge>
          </ng-template>
        </app-data-table-cell>
      </ng-template>

      <ng-template let-item appTableCell="wbs">
        <app-data-table-cell
          (mouseenter)="onMouseenterPropertyTableCell(item.externalId)"
          (click)="onClickPropertyTableCell(item)"
        >
          <ng-template #cellData>
            {{ (item.wbs ? 'general.yes_l' : 'general.no_l') | translate }}
          </ng-template>
        </app-data-table-cell>
      </ng-template>

      <ng-template let-item appTableCell="expose">
        <app-button
          [type]="'context-menu'"
          [iconRight]="'arrow-right'"
          [ghost]="true"
          [useFullContainerSize]="true"
          (mouseenter)="onMouseenterPropertyTableCell(item.externalId)"
          (click)="onClickPropertyTableButton(item)"
        >
        </app-button>
      </ng-template>
    </app-data-table>
  </section>
</div>

<div class="row property-card-list">
  @for (property of properties; track property; let i = $index) {
    <div class="col-12 col-md-6 col-lg-4 col-xl-3 property-card-list__card">
      <app-property-card
        [propertyData]="normalizeObject(property)"
        [enableContextMenu]="false"
        [showLandlordInfo]="false"
        [showAddress]="property.showAddress"
        (showDetailsClickEvent)="redirectToApp(property.applicationLink)"
      ></app-property-card>
    </div>
  }
  <div class="d-flex w-100 justify-content-center align-items-center py-5">
    @if (isLoading) {
      <app-loading-spinner></app-loading-spinner>
    }
  </div>
  <div #anchor class="property-card-list__anchor"></div>
</div>

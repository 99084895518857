import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Pagination, SortOptions } from '@ui/shared/models';
import { isPropertyTypeCommercial, isPropertyTypeGarage } from 'libs/utils';
import * as fromBaseState from 'libs/infrastructure/base-state';

import * as fromPropertyListState from 'homepage/+state/property-list/property-list.reducers';
import * as fromPropertyListActions from 'homepage/+state/property-list/property-list.actions';
import {
  HomepageOptionsResponse,
  HomepagePropertySearchRequest,
  HomepagePropertySearchResponse,
  homepageSortings
} from 'homepage/models';
import { ENVIRONMENT_CONFIG } from 'homepage/core';
import { getInitialContext, InitialContext } from '@ionic/portals';
import { jwtDecode } from 'jwt-decode';
import { PropertiesFilterComponent } from 'homepage/screens/properties/components/properties-filter/properties-filter.component';
import { PropertiesCardListComponent } from 'homepage/screens/properties/components/properties-card-list/properties-card-list.component';
import { AsyncPipe } from '@angular/common';
import { PropertiesMapComponent } from 'homepage/screens/properties/components/properties-map/properties-map.component';
import { LoadingSpinnerComponent } from 'libs/components/legacy';
import { NoDataDisclaimerComponent } from 'libs/components/molecules';
import { PropertiesTableComponent } from 'homepage/screens/properties/components/properties-table/properties-table.component';

export const defaultValues = {
  floor: 20,
  totalRent: 2500
}; // can be extended later if needed
@UntilDestroy()
@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PropertiesFilterComponent,
    PropertiesCardListComponent,
    AsyncPipe,
    PropertiesTableComponent,
    PropertiesMapComponent,
    LoadingSpinnerComponent,
    NoDataDisclaimerComponent,
    PropertiesTableComponent
  ],
  standalone: true
})
export class PropertiesComponent implements OnInit, OnDestroy {
  private store = inject<Store<fromBaseState.AppState>>(Store);
  private route = inject(ActivatedRoute);
  private environment = inject(ENVIRONMENT_CONFIG);

  public properties$: Observable<HomepagePropertySearchResponse[]>;
  public pagination$: Observable<Pagination>;
  public isLoading$: Observable<boolean>;
  public currentLanguage: string;
  public filterForm: FormGroup;
  private token = '';
  public isMapView: boolean;
  public resizeObserver: ResizeObserver;
  public sortValue: SortOptions;
  public options: HomepageOptionsResponse;
  public customerId: number;

  @HostListener('window:message', ['$event'])
  onMessage(message): void {
    // check if iframe is ready to receive messages from app
    message.data === 'ready' ? this.observeHeightChangesForIframe() : null;
  }

  ngOnInit(): void {
    this.store.dispatch(
      fromPropertyListActions.setTileServerUrl({
        tileServerUrl: this.environment?.tileServerUrl
      })
    );
    this.isMapView = false;

    /*
     * Mobile MFE: Initial context is set by shell app and provides the token for a specific customer
     */
    const initialContext: InitialContext = getInitialContext();
    if (initialContext && initialContext.name === 'homepage') {
      this.token = initialContext.value['token'];
    } else {
      this.token = this.route.snapshot.queryParams.token;
    }

    if (this.token) {
      this.store.dispatch(
        fromPropertyListActions.getHomepageOptions({
          token: this.token
        })
      );
      this.customerId = jwtDecode(this.token)['customerId'];
    }
    this.store
      .select(fromPropertyListState.getOptions)
      .pipe(untilDestroyed(this))
      .subscribe(options => {
        if (options) {
          this.options = options;
          this.getProperties(0);
        }
      });
    this.isLoading$ = this.store.select(fromPropertyListState.getLoadingStatus);

    this.store
      .select(fromBaseState.getCurrentLocale)
      .pipe(untilDestroyed(this))
      .subscribe(language => {
        this.currentLanguage = language;
      });
    this.pagination$ = this.store.select(
      fromPropertyListState.getPropertyPagination
    );
  }

  ngOnDestroy(): void {
    this.resizeObserver ? this.resizeObserver.disconnect() : null;
  }

  public get isCardView(): boolean {
    return !this.isMapView && !this.isListView;
  }

  public get isListView(): boolean {
    return this.options?.type == 'LIST';
  }

  public getProperties(pageNumber?: number): void {
    if (!this.options) return;

    const value = this.filterForm.value;
    const propertyTypeIsGarage = isPropertyTypeGarage(value?.typeOfUse);
    const propertyTypeIsCommercial = isPropertyTypeCommercial(value?.typeOfUse);

    const payload: HomepagePropertySearchRequest = {
      // page 0 size 1000 on mapView due to no infinite scroll
      page: this.isCardView ? pageNumber : 0,
      size: this.isCardView ? 18 : 1000,
      token: this.token,
      propertyType: this.formValueStringHelper(value?.typeOfUse),
      wbs:
        propertyTypeIsGarage || propertyTypeIsCommercial
          ? null
          : this.formValueBoolHelper(value?.wbs),
      barrierFree: propertyTypeIsGarage
        ? null
        : this.formValueBoolHelper(value?.barrierFree),
      balconyOrTerrace: propertyTypeIsGarage
        ? null
        : this.formValueBoolHelper(value?.balconyTerrace),
      roomNumber: {
        from:
          propertyTypeIsGarage || propertyTypeIsCommercial
            ? null
            : this.formValueNumHelper(value?.numberOfRooms),
        to: null
      },
      floor: {
        from:
          value?.floor === defaultValues.floor ||
          value?.floor === Infinity ||
          propertyTypeIsGarage
            ? null
            : 0,
        to:
          value?.floor === defaultValues.floor ||
          value?.floor === Infinity ||
          propertyTypeIsGarage
            ? Infinity
            : value?.floor
      },
      totalRentGross: {
        from: null,
        to:
          value.totalRentGross === defaultValues.totalRent
            ? Infinity
            : this.formValueNumHelper(value?.totalRentGross)
      },
      propertySize: {
        from: this.formValueNumHelper(value?.propertySize),
        to: null
      },
      externalId: this.formValueStringHelper(value?.searchByID),
      sort: value?.sortOption
        ? [this.formValueStringHelper(value?.sortOption)]
        : [homepageSortings.createdDesc],
      area: value.location
        ? {
            coordinates: {
              lat: this.formValueNumHelper(value?.lat),
              lon: this.formValueNumHelper(value?.lon)
            },
            radius: value?.location
              ? this.formValueNumHelper(value?.radius)
              : null
          }
        : null
    };

    this.store.dispatch(
      fromPropertyListActions.searchProperties({
        propertySearchInput: payload
      })
    );

    pageNumber === 0
      ? (this.properties$ = this.store.select(
          fromPropertyListState.getPropertySearchOutput
        ))
      : null;
  }

  public sortChanges(form: FormGroup): void {
    this.filterForm = form;
    this.getProperties(0); // fetched on init from properties-filter
  }

  public formValueNumHelper(formValue: number): number | null {
    return formValue ? Number(formValue) : null;
  }

  public formValueBoolHelper(value: boolean): boolean | null {
    return value ? Boolean(value) : null;
  }

  public formValueStringHelper(value: string): string | null {
    return value ? String(value) : null;
  }

  public viewChanges(value: boolean): void {
    this.isMapView = value;
    this.getProperties(0);
  }

  public observeHeightChangesForIframe(): void {
    // observe changes in .properties height and send to iframe
    const container = document.querySelector('.properties');
    this.resizeObserver = new ResizeObserver(entries => {
      const SCREEN_MIN_HEIGHT = 900;
      const { offsetHeight } = <HTMLElement>entries[0].target;
      window.parent.postMessage(
        `${Math.max(SCREEN_MIN_HEIGHT, offsetHeight)}px`,
        '*'
      );
    });
    this.resizeObserver.observe(container);
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { MarketingType, Pagination } from '@ui/shared/models';
import { HomepagePropertySearchResponse } from 'homepage/models';
import { PropertyCardPropertyData } from 'libs/components/molecules/cards/property-card/property-card.model';
import { PropertyCardComponent } from 'libs/components/molecules';
import { LoadingSpinnerComponent } from 'libs/components/legacy';

@Component({
  selector: 'app-properties-card-list',
  templateUrl: './properties-card-list.component.html',
  styleUrls: ['./properties-card-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [PropertyCardComponent, LoadingSpinnerComponent],
  standalone: true
})
export class PropertiesCardListComponent implements OnDestroy, AfterViewInit {
  @Input() properties: HomepagePropertySearchResponse[];
  @Input() pagination: Pagination;
  @Input() isLoading: boolean;
  @Output() intersect = new EventEmitter<number>();
  @ViewChild('anchor', { static: false }) anchor: ElementRef;
  private observer: IntersectionObserver;

  ngAfterViewInit(): void {
    // observer watches for intersection (50%) with invisible element with 5rem height
    // on intersect (if hasNext) and emit pageNum then load new cards
    this.observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting === true) {
          if (this.pagination.hasNext) {
            this.intersect.emit(this.pagination.next);
          }
        }
      },
      {
        threshold: 0.5
      }
    );
    this.observer.observe(this.anchor.nativeElement as HTMLElement);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  public normalizeObject(
    property: HomepagePropertySearchResponse
  ): PropertyCardPropertyData {
    return {
      marketingType: MarketingType.RENT, // added to show price on cards
      type: property.propertyType,
      housingPermissionNeeded: property.wbs,
      attachments: property.titleImage ? [property.titleImage] : [],
      rooms: property.totalRooms,
      ...property
    };
  }

  public redirectToApp(applicationLink: string): void {
    window.open(applicationLink, '_blank');
  }
}
